import {
  RETRIEVE_SELLER,
  RETRIEVE_SELLER_SUCCESS,
  RETRIEVE_SELLER_ERROR
} from "./actions";

import {
  LOGOUT_USER,
  LOGIN_USER_SUCCESS,
  RETRIEVE_ITENS_SUCCESS,
  REGISTER_USER_STEP_FOUR_SUCCESS
} from "../actions";

const INITIAL_STATE = {
  current: {
    id: localStorage.getItem("currentSeller"),
    deliveryAreaKind: { id: null },
    deliveryArea: { radius: null }
  },
  defaultCategories: [
    {
      id: 0,
      kind: "Todos"
    },
    {
      id: 1,
      kind: "Bebidas"
    },
    {
      id: 2,
      kind: "Drinks"
    },
    {
      id: 3,
      kind: "Cervejas"
    },
    {
      id: 4,
      kind: "Produtos"
    },
    {
      id: 5,
      kind: "Outros"
    }
  ],
  itens: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RETRIEVE_SELLER:
      return { ...state, loading: true, error: "" };
    case RETRIEVE_SELLER_SUCCESS:
      return {
        ...state,
        current: action.payload.seller,
        loading: false,
        error: ""
      };
    case RETRIEVE_ITENS_SUCCESS:
      return {
        ...state,
        itens: action.payload.itens,
        loading: false,
        error: ""
      };
    case RETRIEVE_SELLER_ERROR:
      return {
        ...state,
        current: { id: null },
        loading: false,
        error: action.payload.message
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        current: { id: localStorage.getItem("currentSeller") }
      };
    case REGISTER_USER_STEP_FOUR_SUCCESS:
      return {
        ...state,
        current: { id: localStorage.getItem("currentSeller") }
      };
    case LOGOUT_USER:
      return {
        ...state,
        current: { id: null },
        loading: false,
        error: action.payload.message
      };
    default:
      return { ...state };
  }
};
