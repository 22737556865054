import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { registerService } from "../../services/api/register";
import { auth } from "../../services/api";

import {
  REGISTER_USER_STEP_TWO,
  REGISTER_USER_STEP_FOUR,
  REGISTER_USER_GET_STATES,
  REGISTER_USER_GET_NEIGHBORHOOD,
  REGISTER_USER_GET_CITIES,
  REGISTER_USER_KINDS_STORE,
  REGISTER_USER_BANKS,
  REGISTER_USER_STEP_THREE
} from "../actions";

import {
  registerUserStepTwoSucess,
  registerUserStepTwoError,
  registerUserGetStatesSuccess,
  registerUserGetNeighborhoodsSuccess,
  registerUserGetCitiesSuccess,
  registerUserGetKindsStoreSuccess,
  registerUserGetBanksSuccess,
  registerUserStepThreeSuccess,
  registerUserStepFourSuccess,
  registerUserStepFourError
} from "./actions";

import {
  loginUserSuccess
} from "../auth/actions";

export function* watchRegisterUserStepTwo() {
  yield takeEvery(REGISTER_USER_STEP_TWO, registerUserStepTwo);
}

const createUserAsync = async data => {
  return await registerService
    .createUser(data)
    .then(user => user)
    .catch(error => error);
};

const loginWithEmailPasswordAsync = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

function* registerUserStepTwo({ payload }) {

  try {
    const data = yield call(createUserAsync, payload.data);
    if (data && data.erros.length === 0) {
      const loginUser = yield call(loginWithEmailPasswordAsync, payload.data.email, payload.data.password);
      localStorage.setItem("userId", loginUser.user.id);
      localStorage.setItem("accessToken", loginUser.accessToken);
      localStorage.setItem("currentSeller", loginUser.user.currentSeller);
      yield put(loginUserSuccess(loginUser.user, loginUser.accessToken));
      yield put (registerUserStepTwoSucess(loginUser.user));
    } else {
      const errors = data.erros.map(e => e.mensagem);
      yield put (registerUserStepTwoError(errors));
    }
  } catch (e) {
    yield put (registerUserStepTwoError(['Aconteceu algo inesperado, tente novamente.']));
  }
}

export function* watchRegisterUserStepThree() {
  yield takeEvery(REGISTER_USER_STEP_THREE, registerUserStepThree);
}

const getLocationAsync = async data => {
  return await registerService
    .getLocation(data)
    .then(res => {
      return res.body;
    })
    .catch(error => error);
}

function* registerUserStepThree({ payload }) {
  const { data } = payload;
  const responseLocation = yield call(getLocationAsync, data.completedAddress);
  if (responseLocation && responseLocation.results[0]) {
    data.location = responseLocation.results[0].geometry.location;
  }

  yield put(registerUserStepThreeSuccess(data));
}

export function* watchRegisterUserStepFour() {
  yield takeEvery(REGISTER_USER_STEP_FOUR, registerUserStepFour);
}

const createSellerAsync = async data => {
  return await registerService
    .createSeller(data)
    .then(user => user)
    .catch(error => error);
};

function* registerUserStepFour({ payload }) {
  try {
    const { data, history } = payload;

    const response = yield call(createSellerAsync, data);
    if (response && response.erros.length === 0) {
      localStorage.setItem("currentSeller", response.id);
      yield put(registerUserStepFourSuccess(response));
      history.push('/app/success-register')
    } else {
      const errors = response.erros.map(e => e.mensagem);
      yield put(registerUserStepFourError(errors));
    }
  } catch (e) {
    yield put (registerUserStepFourError(['Aconteceu algo inesperado, tente novamente.']));
  }
}

export function* watchRegisterUserGetStates() {
  yield takeEvery(REGISTER_USER_GET_STATES, registerUserGetStates);
}

const getStatesAsync = async () => {
  return await registerService
    .getStates()
    .then(states => states)
    .catch(error => error);
};  

function* registerUserGetStates() {

  // yield put(registerUserStepTwoSucess({user_id: 1}));
  try {
    const response = yield call(getStatesAsync);

    if (response) {
      yield put(registerUserGetStatesSuccess(response));
    } else {
      // yield put(reloadUserError(data.message));
    }
  } catch (e) {
    // yield put(reloadUserError(e.message));
  }
}



export function* watchRegisterUserGetNeighborhoods() {
  yield takeEvery(REGISTER_USER_GET_NEIGHBORHOOD, registerUserGetNeighborhoods);
}

const getNeighborhoodsAsync = async (id) => {
  return await registerService
    .getNeighborhoods(id)
    .then(states => states)
    .catch(error => error);
};  

function* registerUserGetNeighborhoods(data) {
  const id = data.payload;

  // yield put(registerUserStepTwoSucess({user_id: 1}));
  try {
    const response = yield call(getNeighborhoodsAsync, id);
    if (response) {
      yield put(registerUserGetNeighborhoodsSuccess(response));
    } else {
      // yield put(reloadUserError(data.message));
    }
  } catch (e) {
    // yield put(reloadUserError(e.message));
  }
}

export function* watchRegisterUserGetCities() {
  yield takeEvery(REGISTER_USER_GET_CITIES, registerUserGetCities);
}

const getCitiesAsync = async (id) => {
  return await registerService
    .getCities(id)
    .then(states => states)
    .catch(error => error);
};  

function* registerUserGetCities(data) {
  const id = data.payload;

  // yield put(registerUserStepTwoSucess({user_id: 1}));
  try {
    const response = yield call(getCitiesAsync, id);
    if (response) {
      yield put(registerUserGetCitiesSuccess(response));
    } else {
      // yield put(reloadUserError(data.message));
    }
  } catch (e) {
    // yield put(reloadUserError(e.message));
  }
}

export function* watchRegisterKindsStore() {
  yield takeEvery(REGISTER_USER_KINDS_STORE, registerUserKindsStore);
}

const kindsStoreAsync = async () => {
  return await registerService
    .getkindsStore()
    .then(states => states)
    .catch(error => error);
};  

function* registerUserKindsStore() {

  // yield put(registerUserStepTwoSucess({user_id: 1}));
  try {
    const response = yield call(kindsStoreAsync);
    if (response) {
      yield put(registerUserGetKindsStoreSuccess(response));
    } else {
      // yield put(reloadUserError(data.message));
    }
  } catch (e) {
    // yield put(reloadUserError(e.message));
  }
}

export function* watchRegisterUserBanks() {
  yield takeEvery(REGISTER_USER_BANKS, registerUserBanks);
}

const banksAsync = async () => {
  return await registerService
    .getBanks()
    .then(states => states)
    .catch(error => error);
};  

function* registerUserBanks() {

  // yield put(registerUserStepTwoSucess({user_id: 1}));
  try {
    const response = yield call(banksAsync);
    if (response) {
      yield put(registerUserGetBanksSuccess(response));
    } else {
      // yield put(reloadUserError(data.message));
    }
  } catch (e) {
    // yield put(reloadUserError(e.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRegisterUserStepTwo),
    fork(watchRegisterUserGetStates),
    fork(watchRegisterUserGetNeighborhoods),
    fork(watchRegisterUserGetCities),
    fork(watchRegisterKindsStore),
    fork(watchRegisterUserBanks),
    fork(watchRegisterUserStepThree),
    fork(watchRegisterUserStepFour)
  ]);
}
