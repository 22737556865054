import { empty } from "../../../helpers/Utils";
import urlSlug from "url-slug";

const translateStatus = statusId => {
  //  active
  if (statusId === 1) {
    return { active: true, deleted: false };
  }

  // unavailable
  if (statusId === 2) {
    return { active: false, deleted: false };
  }

  // deleted
  if (statusId === 3) {
    return { active: false, deleted: true };
  }
};

const deserializeProduct = data => {
  return {
    id: data.id,
    title: data.nome,
    category: data.categoriaId,
    active: data.ativo,
    description: data.detalhe,
    order: data.ordem,
    image: data.urlImg,
    kind: empty(data.tipoProduto)
      ? {}
      : deserializeProductKind(data.tipoProduto)
  };
};

const deserializeProductKind = data => {
  return {
    id: data.id,
    title: data.tipo
  };
};

const deserializeVariationKindList = data =>
  data.map(item => deserializeVariationKind(item));

const deserializeVariationKind = data => {
  return {
    id: data.id,
    title: data.nome,
    required: data.obrigatorio,
    minimum: data.qtdMin,
    maximum: data.qtdMax,
    deleted: data.excluido,
    items: data.itensAcompanhamento.map(item =>
      deserializeVariationKindItem(item)
    )
  };
};

const deserializeVariationKindItem = data => {
  return {
    id: data.id,
    title: data.nome,
    description: data.detalhe,
    price: data.valor,
    statusId: data.statusItemId,
    statusItem: data.statusItem,
    ...translateStatus(data.statusItemId)
  };
};

const deserializeStoreProduct = data => {
  return {
    id: data.id,
    price: data.valor,
    statusId: data.statusItemId,
    status: data.statusItem,
    base64: data.base64,
    storeId: data.barracaId,
    productId: data.produtoId,
    touched: 0,
    categoryId: data.categoriaId,
    order: data.ordem,
    category: empty(data.categoria)
      ? {}
      : deserializeStoreProductCategory(data.categoria),
    product: deserializeProduct(data.produto),
    variationKind: deserializeVariationKindList(data.gruposAcompanhamento),
    ...translateStatus(data.statusItemId)
  };
};

const deserializeStoreProductList = data =>
  data.map(item => deserializeStoreProduct(item));

const deserializeStoreProductCategory = data => {
  const category =
    typeof data.categoria !== "undefined" ? data.categoria : data;
  return {
    id: category.id,
    title: category.nome,
    order: category.ordem,
    sellerId: category.barracaId,
    active: category.ativa
  };
};

const deserializeStoreItens = data => {
  const items = [];

  data.forEach(item => {
    items.push({
      price: 0,
      imported: false,
      id: item.id,
      productId: item.tipoProduto.id,
      name: item.nome,
      hide: false,
      description: item.detalhe,
      urlImg: item.urlImg
    });
  });
  
  return items;
};

const deserializeStoreProductStatus = data => {
  return {
    id: data.id,
    title: data.status
  };
};

const deserializeStoreProductCategoryList = data =>
  data.categorias.map(item => deserializeStoreProductCategory(item));

const deserializeStoreProductStatusList = data =>
  data.map(item => deserializeStoreProductStatus(item));

const deserializeImage = data => {
  return data ? data.base64 : "";
};

const deserializeStateList = data =>
  data.estados.map(item => deserializeState(item));

const deserializeState = data => {
  const resource = getResource(data, "estado");

  return {
    id: resource.id,
    title: resource.nome,
    code: resource.sigla,
    country: deserializeCountry(resource.pais)
  };
};

const deserializeCountry = data => {
  const resource = data.pais ? data.pais : data;

  return {
    id: resource.id,
    title: resource.nome
  };
};

const deserializeCityList = data =>
  data.cidades.map(item => deserializeCity(item));

const deserializeCity = data => {
  const resource = getResource(data, "cidade");

  return {
    id: resource.id,
    title: resource.nome,
    state: deserializeState(resource.estado)
  };
};

const deserializeNeighborhoodList = data =>
  data.bairros.map(item => deserializeNeighborhood(item));

const deserializeNeighborhood = data => {
  const resource = getResource(data, "bairro");

  return {
    id: resource.id,
    title: resource.nome,
    city: deserializeCity(resource.cidade)
  };
};

const deserializeDeliveryAreaNeighborhoodList = data => {
  const resource = getResource(data, "bairros");
  return resource.map(item => deserializeDeliveryAreaNeighborhood(item));
};

const deserializeDeliveryAreaNeighborhood = data => {
  const resource = getResource(data, "bairro");
  return {
    id: resource.id,
    title: resource.nome,
    deliveryPrice: resource.valorEntrega
  };
};

const deserializeDeliveryAreaNeighborhoodCityList = data => {
  return data.cidades.map(item => {
    return {
      city: deserializeCity(item),
      neighborhoods: deserializeDeliveryAreaNeighborhoodList(item)
    };
  });
};

const deserializeDeliveryAreaKind = data => {
  const resource = getResource(data, "tipoAreaCobertura");
  return {
    id: resource.id,
    title: resource.nome,
    slug: urlSlug(resource.nome)
  };
};

const deserializeSeller = data => {
  const resource = getResource(data, "barraca");

  return {
    id: resource.id,
    title: resource.nome,
    number: resource.numero,
    latitude: parseFloat(resource.latitude, 10),
    longitude: parseFloat(resource.longitude, 10),
    status: resource.status,
    order: resource.ordem,
    image: resource.urlImg,
    kind: resource.tipoBarracaId,
    deliveryArea: {
      radius: resource.raioEntrega
    },
    deliveryPrice: resource.taxaEntrega,
    serviceTax: resource.taxaServico,
    deliveryKind: deserializeDeliveryKindList(resource.tipoEntrega),
    deliveryAreaKind: deserializeDeliveryAreaKind(resource.tipoAreaCobertura)
  };
};

const deserializeDeliveryAreaKindList = data =>
  data.map(item => deserializeDeliveryAreaKind(item));

const deserializeDeliveryKindList = data =>
  data.map(item => deserializeDeliveryKind(item));

const deserializeDeliveryKind = data => {
  const resource = getResource(data, "tipoEntrega");
  return {
    id: resource.id,
    kind: resource.tipo,
    fields: resource.campos
  };
};

const deserializeDeliveryPrice = data => {
  return {
    id: data.id,
    deliveryRadius: data.raioEntrega,
    deliveryPrice: data.taxaEntrega
  };
};

const deserializeNeighborhoodDeliveryPrice = data => {
  return {
    id: data.id,
    deliveryPrice: data.valorEntrega,
    title: data.nome,
    active: data.ativo
  };
};

const getResource = (data, key) => (data[key] ? data[key] : data);

export {
  deserializeImage,
  deserializeProduct,
  deserializeSeller,
  deserializeDeliveryAreaKind,
  deserializeDeliveryAreaKindList,
  deserializeStoreProduct,
  deserializeStoreProductList,
  deserializeStoreProductCategoryList,
  deserializeStoreProductCategory,
  deserializeStoreProductStatus,
  deserializeStoreProductStatusList,
  deserializeProductKind,
  deserializeVariationKind,
  deserializeVariationKindItem,
  deserializeCity,
  deserializeCityList,
  deserializeNeighborhoodDeliveryPrice,
  deserializeDeliveryAreaNeighborhood,
  deserializeDeliveryAreaNeighborhoodList,
  deserializeDeliveryAreaNeighborhoodCityList,
  deserializeDeliveryPrice,
  deserializeState,
  deserializeStateList,
  deserializeCountry,
  deserializeNeighborhood,
  deserializeNeighborhoodList,
  deserializeStoreItens
};
