import {
  RETRIEVE_DEFAULT_CATEGORIES,
  RETRIEVE_DEFAULT_CATEGORIES_SUCCESS,
  RETRIEVE_ITENS,
  RETRIEVE_ITENS_SUCCESS
} from "../actions";

export const RETRIEVE_SELLER = "RETRIEVE_SELLER";
export const RETRIEVE_SELLER_ERROR = "RETRIEVE_SELLER_ERROR";
export const RETRIEVE_SELLER_SUCCESS = "RETRIEVE_SELLER_SUCCESS";

export const retrieveSeller = sellerId => ({
  type: RETRIEVE_SELLER,
  payload: { sellerId }
});

export const retrieveSellerSuccess = seller => ({
  type: RETRIEVE_SELLER_SUCCESS,
  payload: { seller }
});

export const retrieveDefaultCategories = sellerId => ({
  type: RETRIEVE_DEFAULT_CATEGORIES,
  payload: { sellerId }
});

export const retrieveDefaultCategoriesSuccess = seller => ({
  type: RETRIEVE_DEFAULT_CATEGORIES_SUCCESS,
  payload: { seller }
});

export const retrieveItens = itens => ({
  type: RETRIEVE_ITENS,
  payload: { itens }
});

export const retrieveItensSuccess = itens => ({
  type: RETRIEVE_ITENS_SUCCESS,
  payload: { itens }
});

export const retrieveSellerError = message => ({
  type: RETRIEVE_SELLER_ERROR,
  payload: { message }
});
