import { date } from "yup";
import { empty, getStatusId } from "../../../helpers/Utils";

const serializeProduct = (data) => {
  return {
    id: data.id,
    nome: data.title,
    categoria: data.category,
    ativo: data.active,
    detalhe: data.description === "" ? " " : data.description,
    ordem: data.order || 0,
    tipoProdutoId: data.productKindId || 5,
  };
};

const serializeProductKind = (data) => {
  return {
    tipo: data.kind,
  };
};

const serializeVariationKindList = (data) => {
  if (empty(data)) {
    return [];
  }

  return data.map((item) => serializeVariationKind(item));
};

const serializeVariationKind = (data) => {
  return {
    id: data.id,
    nome: data.title,
    obrigatorio: data.required,
    qtdMin: data.minimum,
    qtdMax: data.maximum,
    excluido: data.deleted,
    itensAcompanhamento: serializeVariationKindItemList(data.items),
  };
};

const serializeVariationKindItem = (data) => {
  return {
    id: data.id,
    nome: data.title,
    valor: data.price,
    detalhe: data.description === "" ? " " : data.description,
    statusItemId: getStatusId(data),
  };
};

const serializeVariationKindItemList = (data = []) => {
  return data.map((item) => serializeVariationKindItem(item));
};

const serializeStoreProduct = (data) => {
  return {
    id: data.id,
    valor: data.price,
    statusItemId: data.statusId,
    base64: data.image,
    barracaId: data.sellerId,
    produto: empty(data.product)
      ? data.product
      : serializeProduct({ ...data.product, order: data.order }),
    grupoAcompanhamento: serializeVariationKindList(data.variationKind),
    categoriaId: data.categoryId,
    ordem: data.order,
  };
};

const serializeStoreProductCategory = (data) => {
  return {
    id: data.id,
    nome: data.title,
    ordem: data.order,
    ativa: data.active,
    barracaId: data.sellerId,
  };
};

const serializeCreateItem = (data) => {
  return {
    valor: data.price.toString().replace(",", "."),
    statusItemId: 1,
    barracaId: data.currentSeller,
    produtoId: data.productId,
    categoriaId: data.categoryId,
  };
};

const serializeState = (data) => {
  return {
    id: data.id,
    nome: data.title,
    sigla: data.code,
    country: serializeCountry(data.pais),
  };
};

const serializeCountry = (data) => {
  return {
    id: data.id,
    nome: data.title,
  };
};

const serializeCity = (data) => {
  return {
    id: data.id,
    nome: data.title,
    estado: serializeState(data.state),
  };
};
const serializeNeighborhoodList = (data) =>
  data.map((item) => serializeNeighborhood(item));

const serializeNeighborhood = (data) => {
  return {
    id: data.id,
    nome: data.title,
    ativo: data.active,
    valorEntrega: data.deliveryPrice,
    cidade: data.city ? serializeCity(data.city) : undefined,
  };
};

const serializeDeliveryPrice = (data) => {
  return {
    id: data.sellerId,
    raioEntrega: data.deliveryRadius,
    taxaEntrega: data.deliveryPrice,
  };
};

const serializeNeighborhoodDeliveryPrice = (data) => {
  return {
    id: data.id,
    nome: data.title,
    ativo: data.active,
    valorEntrega: data.deliveryPrice,
  };
};

export {
  serializeProduct,
  serializeStoreProduct,
  serializeStoreProductCategory,
  serializeProductKind,
  serializeVariationKind,
  serializeNeighborhood,
  serializeNeighborhoodList,
  serializeNeighborhoodDeliveryPrice,
  serializeDeliveryPrice,
  serializeCity,
  serializeCountry,
  serializeState,
  serializeCreateItem,
};
