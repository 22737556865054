import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { sellerApi, storeProduct } from "../../services/api";
import {
  RETRIEVE_SELLER,
  RETRIEVE_ITENS
} from "../actions";
import { retrieveSellerError, retrieveSellerSuccess, retrieveItensSuccess } from "./actions";

const retrieveSellerAsync = async sellerId => {
  return await sellerApi
    .retrieve(sellerId)
    .then(seller => seller)
    .catch(error => error);
};

export function* watchRetrieveSeller() {
  yield takeEvery(RETRIEVE_SELLER, retrieveSeller);
}

function* retrieveSeller({ payload }) {
  try {
    const data = yield call(retrieveSellerAsync, payload.sellerId);
    if (data) {
      yield put(retrieveSellerSuccess(data));
    } else {
      yield put(retrieveSellerError(data.message));
    }
  } catch (e) {
    yield put(retrieveSellerError(e.message));
  }
}

const retrieveItensAsync = async sellerId => {
  return await storeProduct
    .retrieveItens()
    .then(itens => itens)
    .catch(error => error);
};

export function* watchRetrieveItens() {
  yield takeEvery(RETRIEVE_ITENS, retrieveItens);
}

function* retrieveItens({ payload }) {
  const data = yield call(retrieveItensAsync, payload.sellerId);
  if (data) {
    yield put(retrieveItensSuccess(data));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRetrieveSeller),
    fork(watchRetrieveItens)
  ]);
}
