import request from 'superagent';
import { apiFetcher } from "./index";
class ApiError extends Error {
  constructor(response, body) {
    super();
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = "ApiError";
    this.body = body;
    this.errors = body.erros;
    this.message = body.mensagem ? body.mensagem : this.extractErrorFromBody();
    console.log("message", this.errors);
  }

  extractErrorFromBody = () => this.errors.map(item => `${item.mensagem}\n`);
}

export const registerService = {
  createUser: async (data) => {
    return await apiFetcher({
      path: `Usuario/Barraqueiro`,
      method: "POST",
      body: data,
      ignoreErrors: true
    });
  },
  createSeller: async (data) => {
    return await apiFetcher({
      path: `Barraca`,
      method: "POST",
      body: data,
    });
  },
  getStates: async () => {
    return await apiFetcher({
      path: `Endereco/Estado`,
      method: "GET",
      ignoreErrors: true
    });
  },
  getNeighborhoods: async (cityId) => {
    return await apiFetcher({
      path: `Endereco/Cidade/${cityId}/Bairro`,
      method: "GET",
      ignoreErrors: true
    });
  },
  getCities: async (stateId) => {
    return await apiFetcher({
      path: `Endereco/Estado/${stateId}/Cidade`,
      method: "GET",
      ignoreErrors: true
    });
  },
  getkindsStore: async () => {
    return await apiFetcher({
      path: `Barraca/Tipo`,
      method: "GET",
      ignoreErrors: true
    });
  },
  getBanks: async () => {
    return await apiFetcher({
      path: `Banco`,
      method: "GET",
      ignoreErrors: true
    });
  },
  getLocation: async (address) => {
    return request.get(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBGIMKbP8yjLWSKmxYcTqAl1rXN-0GWtYA&address=${address}`);
  }
};