import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  RELOAD_USER,
  RELOAD_USER_SUCCESS,
  RELOAD_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR
} from "../actions";

const INIT_STATE = {
  user: {
    id: localStorage.getItem("userId"),
    currentSeller: localStorage.getItem("currentSeller")
  },
  accessToken: localStorage.getItem("accessToken"),
  forgotUserMail: "",
  newPassword: "",
  resetPasswordCode: "",
  loading: false,
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RELOAD_USER:
      return { ...state, loading: true, error: "" };
    case RELOAD_USER_SUCCESS:
      return { ...state, user: action.payload.user, loading: false, error: "" };
    case RELOAD_USER_ERROR:
      return {
        ...state,
        user: {id: null},
        loading: false,
        error: action.payload.message
      };
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
        error: ""
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: {id: null},
        accessToken: null,
        error: action.payload.message
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: "" };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: ""
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: "",
        error: action.payload.message
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: "" };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: "",
        error: ""
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: "",
        resetPasswordCode: "",
        error: action.payload.message
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: "" };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.id, error: "" };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: {id: null},
        error: action.payload.message
      };
    case LOGOUT_USER:
      return { ...state, user: {id: null}, error: "" };
    default:
      return { ...state };
  }
};
