import { todaPraiaApiConfig } from "../../constants/defaultValues";
import withQuery from "with-query";
import {
  deserializeImage,
  deserializeProduct,
  deserializeDeliveryAreaKindList,
  deserializeDeliveryAreaNeighborhoodList,
  deserializeDeliveryAreaNeighborhoodCityList,
  deserializeStoreProduct,
  deserializeStoreProductList,
  deserializeStoreProductCategory,
  deserializeStoreProductCategoryList,
  deserializeStoreProductStatusList,
  deserializeNeighborhoodDeliveryPrice,
  deserializeSeller,
  deserializeStateList,
  deserializeCityList,
  deserializeNeighborhoodList,
  deserializeStoreItens,
  deserializeDeliveryPrice,
} from "./serializers/from";
import { serializeNeighborhoodList } from "./serializers/to";
import {
  serializeProduct,
  serializeStoreProduct,
  serializeStoreProductCategory,
  serializeCreateItem,
  serializeDeliveryPrice,
  serializeNeighborhoodDeliveryPrice,
} from "./serializers/to";

class ApiError extends Error {
  constructor(response, body) {
    super();
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = "ApiError";
    this.body = body;
    this.errors = body.erros.map((item) => ({
      code: item.codigoErro,
      message: item.mensagem,
    }));

    // if (process.env.NODE_ENV === "development") {
    //   console.log("message", this.errors);
    // }
  }

  get isSingle() {
    return this.errors.length === 1;
  }

  get message() {
    return this.body.mensagem
      ? this.body.mensagem
      : this.extractErrorFromBody();
  }

  get code() {
    return this.body.codigo ? this.body.codigo : this.extractCodeFromBody();
  }

  extractErrorFromBody = () => {
    if (this.isSingle) {
      return this.errors[0].message;
    }

    return this.errors.map((item) => `${item.message}\n`);
  };

  extractCodeFromBody = () => {
    if (this.isSingle) {
      return this.errors[0].code;
    }

    return this.errors.map((item) => `${item.code}\n`);
  };
}

const apiFetcher = async ({
  method,
  path,
  ignoreErrors = false,
  queryParams = {},
  body = null,
  token = null,
}) => {
  const baseUrl = `${todaPraiaApiConfig.baseUrl}/api`;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token,
  };

  if (body) console.log("BODY: ", body);

  const url = withQuery(`${baseUrl}/${path}`, queryParams);
  const requestOptions = {
    method: method,
    headers: token ? { ...headers, Authorization: token } : headers,
  };

  if (body !== null) {
    requestOptions.body = JSON.stringify(body);
  }

  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    return null;
  }
  const responseBody = await response.json();

  // if (process.env.NODE_ENV === "development") {
  //   console.log("ApiRequestUrl: ", url);
  //   console.log("ApiRequest: ", requestOptions);
  //   console.log("ApiResponse: ", response);
  //   console.log("ApiBody: ", responseBody);
  // }

  if (responseBody.sucesso === false && !ignoreErrors) {
    throw new ApiError(response, responseBody);
  }

  return responseBody;
};

const apiFetcherWithToken = (args) =>
  apiFetcher({ ...args, token: localStorage.getItem("accessToken") });

const translateUserResponse = (response) => {
  const hasError = () => typeof response === "undefined";
  if (hasError()) {
    console.log("Login Request Error: ", response);
    return null;
  }

  const userData =
    typeof response.usuario !== "undefined" ? response.usuario : response;

  const getSeller = (data) => {
    const sellerData =
      typeof userData.barracasId !== "undefined"
        ? userData.barracasId
        : userData.barracas.map((item) => item.barracaId);

    return {
      seller: sellerData,
      currentSeller: sellerData[0],
    };
  };

  return {
    user: {
      id: userData.id,
      email: userData.email,
      fullName: userData.nome,
      roleId: userData.tipoUsuarioId,
      accessId: userData.tipoAcessoId,
      acceptedUserTerms: userData.termoDeUsoAceito,
      birthdate: userData.dataNascimento,
      documentNumber: userData.cpf,
      avatar: userData.urlImg,
      ...getSeller(),
    },
    accessToken: response.accessToken,
  };
};

const translateProductType = (data) => ({ id: data.id, kind: data.tipo });

const translateProduct = (data) => {
  const hasError = () => typeof data === "undefined";
  if (hasError()) {
    console.log("Login Request Error: ", data);
    return null;
  }

  return {
    id: data.id,
    title: data.nome,
    active: data.ativo,
    description: data.detalhe,
    productType: translateProductType(data.tipoProduto),
    order: data.ordem,
    birthdate: data.dataNascimento,
    documentNumber: data.cpf,
    avatar: data.urlImg,
  };
};

const translateProductList = (data) =>
  data.map((item) => translateProduct(item));

const auth = {
  signInWithEmailAndPassword: async (email, password) =>
    translateUserResponse(
      await apiFetcher({
        path: "login",
        method: "post",
        body: { email: email, password: password },
      })
    ),
};

const product = {
  list: async () => {
    return translateProductList(
      await apiFetcher({
        path: `Produto`,
        method: "GET",
        token: localStorage.getItem("accessToken"),
      })
    );
  },

  create: async (body) => {
    return deserializeProduct(
      await apiFetcher({
        path: `Produto`,
        method: "POST",
        body: serializeProduct(body),
        token: localStorage.getItem("accessToken"),
      })
    );
  },
};

const storeProduct = {
  list: async (storeId) => {
    return deserializeStoreProductList(
      await apiFetcher({
        path: `Item/Barraca/${storeId}`,
        method: "GET",
        token: localStorage.getItem("accessToken"),
      })
    );
  },
  categories: async (storeId) => {
    return deserializeStoreProductCategoryList(
      await apiFetcher({
        path: `Item/Barraca/${storeId}/Categoria`,
        method: "GET",
        token: localStorage.getItem("accessToken"),
        ignoreErrors: true, // remove when sucesso response starts to working again
      })
    );
  },
  listActive: async (storeId) => {
    return translateProductList(
      await apiFetcher({
        path: `Item/${storeId}/ativo`,
        method: "GET",
        token: localStorage.getItem("accessToken"),
      })
    );
  },
  create: async (body) => {
    const serializedBody = serializeStoreProduct(body);
    return deserializeStoreProduct(
      await apiFetcher({
        path: `Item/Personalizado`,
        method: "POST",
        body: serializedBody,
        token: localStorage.getItem("accessToken"),
      })
    );
  },
  partialUpdate: async (body) => {
    const serializedBody = serializeStoreProduct(body);
    const response = await apiFetcher({
      path: `Item/Personalizado`,
      method: "PATCH",
      body: serializedBody,
      token: localStorage.getItem("accessToken"),
    });

    if (body.statusId === 3 && response.sucesso) return;

    return deserializeStoreProduct(response);
  },

  statusList: async () => {
    return deserializeStoreProductStatusList(
      await apiFetcher({
        path: `Item/Status`,
        method: "GET",
        token: localStorage.getItem("accessToken"),
      })
    );
  },
  getActiveStatus: async () => {
    const statusListResponse = await this.statusList();
    return statusListResponse.filter((item) => item.title === "Ativo")[0]?.id;
  },
  createCategory: async (data) => {
    return deserializeStoreProductCategory(
      await apiFetcher({
        path: `Item/Categoria`,
        method: "POST",
        body: serializeStoreProductCategory(data),
        token: localStorage.getItem("accessToken"),
      })
    );
  },
  createItem: async (data) => {
    return await apiFetcher({
      path: `Item`,
      method: "POST",
      body: serializeCreateItem(data),
      token: localStorage.getItem("accessToken"),
    });
  },
  retrieveItens: async () => {
    return deserializeStoreItens(
      await apiFetcher({
        path: `Produto/Ativo`,
        method: "GET",
        token: localStorage.getItem("accessToken"),
      })
    );
  },
  deleteCategory: async (sellerId, categoryId) => {
    return await apiFetcherWithToken({
      path: `Item/Barraca/${sellerId}/Categoria/${categoryId}`,
      method: "DELETE",
    });
  },
};

const image = {
  get: async (id, kind = "item") => {
    return deserializeImage(
      await apiFetcher({
        path: `Storage/${kind}container/${id}.jpeg`,
        method: "GET",
      })
    );
  },
};

const user = {
  get: async (id) =>
    translateUserResponse(
      await apiFetcher({
        path: `Usuario/${id}`,
        method: "GET",
        token: localStorage.getItem("accessToken"),
      })
    ),
};

const sellerApi = {
  retrieve: async (id) => {
    return deserializeSeller(
      await apiFetcher({
        path: `Barraca/${id}`,
        method: "GET",
      })
    );
  },
  deliveryAreaKindList: async () => {
    return deserializeDeliveryAreaKindList(
      await apiFetcher({
        path: `Barraca/TipoAreaCobertura`,
        method: "GET",
      })
    );
  },
  setDeliveryAreaKind: async (sellerId, deliveryAreaKindId) => {
    return await apiFetcherWithToken({
      path: `Barraca/${sellerId}/TipoAreaCobertura/${deliveryAreaKindId}`,
      method: "PATCH",
    });
  },

  setDeliveryNeighborhoods: async (sellerId, neighborhoodList) => {
    return deserializeDeliveryAreaNeighborhoodList(
      await apiFetcherWithToken({
        path: `Barraca/${sellerId}/Bairro/Entrega`,
        method: "POST",
        body: { bairros: serializeNeighborhoodList(neighborhoodList) },
      })
    );
  },

  getDeliveryNeighborhoodList: async (sellerId, neighborhoodList) => {
    return deserializeDeliveryAreaNeighborhoodCityList(
      await apiFetcherWithToken({
        path: `Barraca/${sellerId}/Bairro/Entrega`,
        method: "GET",
      })
    );
  },

  setDeliveryPrice: async (sellerId, body) => {
    return deserializeDeliveryPrice(
      await apiFetcherWithToken({
        path: `Barraca/Entrega/Raio`,
        method: "PATCH",
        body: serializeDeliveryPrice({ sellerId, ...body }),
      })
    );
  },

  setNeighborhoodDeliveryPrice: async (sellerId, neighborhoodId, body) => {
    return deserializeNeighborhoodDeliveryPrice(
      await apiFetcherWithToken({
        path: `Barraca/${sellerId}/Bairro/${neighborhoodId}/Entrega`,
        method: "PATCH",
        body: serializeNeighborhoodDeliveryPrice(body),
      })
    );
  },
};

const addressApi = {
  getStateList: async () => {
    return deserializeStateList(
      await apiFetcherWithToken({
        path: "Endereco/Estado",
        method: "GET",
        ignoreErrors: true,
      })
    );
  },

  getCityList: async (stateId) => {
    return deserializeCityList(
      await apiFetcherWithToken({
        path: `Endereco/Estado/${stateId}/Cidade`,
        method: "GET",
        ignoreErrors: true,
      })
    );
  },

  getNeighborhoodList: async (cityId) => {
    return deserializeNeighborhoodList(
      await apiFetcherWithToken({
        path: `Endereco/Cidade/${cityId}/Bairro`,
        method: "GET",
        ignoreErrors: true,
      })
    );
  },
};

export {
  apiFetcher,
  auth,
  user,
  product,
  storeProduct,
  image,
  sellerApi,
  addressApi,
};
