
import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import sellerSagas from "./seller/saga";
import register from './register/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    sellerSagas(),
    register()
  ]);
}
