import {
  REGISTER_USER_STEP_ONE,
  REGISTER_USER_STEP_TWO,
  REGISTER_USER_STEP_TWO_SUCCESS,
  REGISTER_USER_STEP_TWO_ERROR,
  REGISTER_USER_STEP_THREE,
  REGISTER_USER_STEP_THREE_SUCCESS,
  REGISTER_USER_STEP_FOUR,
  REGISTER_USER_STEP_FOUR_SUCCESS,
  REGISTER_USER_STEP_FOUR_ERROR,
  REGISTER_USER_GET_STATES_SUCCESS,
  REGISTER_USER_GET_NEIGHBORHOOD_SUCCESS,
  REGISTER_USER_GET_CITIES_SUCCESS,
  REGISTER_USER_KINDS_STORE_SUCCESS,
  REGISTER_USER_BANKS_SUCCESS,
  REGISTER_USER_SET_STEP
} from "../actions";

const INIT_STATE = {
  step: 1,
  userId: localStorage.getItem("userId"),
  loading: false,
  fullName: "",
  email: "",
  password: "",
  confirmPassword: "",
  phoneCode: "",
  phone: "",
  rg: "",  
  cpf: "",
  birthday: "",
  states: [],
  neighborhoods: [],
  cities: [],
  kindsStore: [],
  banks: [],
  storeName: "",
  cpfOrCnpj: "",
  kindStore: "",
  state: "",
  city: "",
  neighborhood: "",
  address: "",
  location: {},
  kindAccountBank: "",
  bank: "",
  kindPerson: "",
  agency: "",
  agencyDigit: "",
  account: "",
  accountDigit: "",
  errors: []
};

export default (state = INIT_STATE, action) => {
  
  switch (action.type) {
    case REGISTER_USER_SET_STEP:
      return { ...state, step: action.payload.data, loading: false, errors: [] };
    case REGISTER_USER_STEP_ONE:
      return { 
        ...state,
        step: 2,
        loading: false,
        errors: [],
        ...action.payload.data
      };
    case REGISTER_USER_STEP_TWO:
      return { ...state, 
        loading: true,
        errors: []
      };
    case REGISTER_USER_STEP_TWO_SUCCESS:
      return { ...state, 
        step: 3,
        user: action.payload.data,
        userId: action.payload.data.id,
        loading: false,
        errors: []
      };
    case REGISTER_USER_STEP_TWO_ERROR:
      return { ...state, 
        step: 2,
        loading: false,
        errors: action.payload.data
      };
    case REGISTER_USER_STEP_THREE: 
      return { ...state, 
        loading: true,
        errors: []
      };
    case REGISTER_USER_STEP_THREE_SUCCESS:
      return { ...state, ...action.payload.data, step: 4, loading: false, errors: [] };
    case REGISTER_USER_STEP_FOUR:
      return { ...state, loading: true, errors: [] };
    case REGISTER_USER_STEP_FOUR_SUCCESS:
      return { ...state, step: 5, loading: false, errors: [] };
    case REGISTER_USER_STEP_FOUR_ERROR:
      return { ...state, step: 4, loading: false, errors: action.payload.data };
    case REGISTER_USER_GET_STATES_SUCCESS:
      return { ...state, states: action.payload.payload.estados, cities: [], neighborhoods: [] };
    case REGISTER_USER_GET_NEIGHBORHOOD_SUCCESS:
      return { ...state, neighborhoods: action.payload.payload.bairros };
    case REGISTER_USER_GET_CITIES_SUCCESS:
      return { ...state, cities: action.payload.payload.cidades, neighborhoods: [] };
    case REGISTER_USER_KINDS_STORE_SUCCESS:
      return { ...state, kindsStore: action.payload.payload };
    case REGISTER_USER_BANKS_SUCCESS:
      return { ...state, banks: action.payload.payload };
    default:
      return { ...state };
  }
};
