
import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
import seller from "./seller/reducers";
import register from './register/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  register,
  seller
});

export default reducers;
