import { defaultDirection } from "../constants/defaultValues";

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "." + mm + "." + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes();
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl",
  };
};

export const setDirection = (localValue) => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};

export const empty = (data) => {
  if (typeof data === "undefined" || data === false || data === null) {
    return true;
  }

  if (typeof data === "object") {
    return Object.keys(data).length === 0;
  }

  return false;
};

export const isEmpty = empty;

export const sortByKey = (arr, key) => {
  return Array.from(arr).sort((a, b) => {
    if (a[key] > b[key]) {
      return 1;
    } else if (a[key] < b[key]) {
      return -1;
    } else {
      return 0;
    }
  });
};

export const normalizeValue = (number) => {
  // all input numbers must be a float point (for the cents portion). This is a fallback in case of integer ones.
  const safeNumber = Number.isInteger(number) ? Number(number) * 100 : number;

  // strips everything that is not a number (positive or negative)
  // then divide it by 10 power the maximum fraction digits.
  return Number(safeNumber.toString().replace(/[^0-9-]/g, "")) / 10 ** 2;
};

export const getStatusId = (item) => {
  if (item.deleted) {
    return 3;
  }

  if (item.active === false) {
    return 2;
  }

  return 1;
};
