/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RELOAD_USER = "RELOAD_USER";
export const RELOAD_USER_SUCCESS = "RELOAD_USER_SUCCESS";
export const RELOAD_USER_ERROR = "RELOAD_USER_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";


/* Register */ 
export const REGISTER_USER_SET_STEP = "REGISTER_USER_SET_STEP"; 
export const REGISTER_USER_STEP_ONE = "REGISTER_USER_STEP_ONE"; 
export const REGISTER_USER_STEP_TWO = "REGISTER_USER_STEP_TWO"; 
export const REGISTER_USER_STEP_TWO_SUCCESS = "REGISTER_USER_STEP_TWO_SUCCESS"; 
export const REGISTER_USER_STEP_TWO_ERROR = "REGISTER_USER_STEP_TWO_ERROR"; 
export const REGISTER_USER_STEP_THREE = "REGISTER_USER_STEP_THREE";
export const REGISTER_USER_STEP_THREE_SUCCESS = "REGISTER_USER_STEP_THREE_SUCCESS";
export const REGISTER_USER_STEP_FOUR = "REGISTER_USER_STEP_FOUR";
export const REGISTER_USER_STEP_FOUR_SUCCESS = "REGISTER_USER_STEP_FOUR_SUCCESS";
export const REGISTER_USER_STEP_FOUR_ERROR = "REGISTER_USER_STEP_FOUR_ERROR";
export const REGISTER_USER_GET_STATES = "REGISTER_USER_GET_STATES"; 
export const REGISTER_USER_GET_STATES_SUCCESS = "REGISTER_USER_GET_STATES_SUCCESS";
export const REGISTER_USER_GET_NEIGHBORHOOD = "REGISTER_USER_GET_NEIGHBORHOOD";
export const REGISTER_USER_GET_NEIGHBORHOOD_SUCCESS = "REGISTER_USER_GET_NEIGHBORHOOD_SUCCESS";
export const REGISTER_USER_GET_CITIES = "REGISTER_USER_GET_CITIES";
export const REGISTER_USER_GET_CITIES_SUCCESS = "REGISTER_USER_GET_CITIES_SUCCESS";
export const REGISTER_USER_KINDS_STORE = "REGISTER_USER_KINDS_STORE";
export const REGISTER_USER_KINDS_STORE_SUCCESS = "REGISTER_USER_KINDS_STORE_SUCCESS";
export const REGISTER_USER_BANKS = "REGISTER_USER_BANKS";
export const REGISTER_USER_BANKS_SUCCESS = "REGISTER_USER_BANKS_SUCCESS";

/* Seller */
export const RETRIEVE_DEFAULT_CATEGORIES = "RETRIEVE_DEFAULT_CATEGORIES";
export const RETRIEVE_DEFAULT_CATEGORIES_SUCCESS = "RETRIEVE_DEFAULT_CATEGORIES_SUCCESS";
export const RETRIEVE_ITENS = "RETRIEVE_ITENS";
export const RETRIEVE_ITENS_SUCCESS = "RETRIEVE_ITENS_SUCCESS";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./seller/actions";
export * from "./register/actions";
