import {
  REGISTER_USER_SET_STEP,
  REGISTER_USER_STEP_ONE,
  REGISTER_USER_STEP_TWO,
  REGISTER_USER_STEP_TWO_SUCCESS,
  REGISTER_USER_STEP_TWO_ERROR,
  REGISTER_USER_STEP_THREE,
  REGISTER_USER_STEP_THREE_SUCCESS,
  REGISTER_USER_STEP_FOUR,
  REGISTER_USER_STEP_FOUR_SUCCESS,
  REGISTER_USER_STEP_FOUR_ERROR,
  REGISTER_USER_GET_STATES,
  REGISTER_USER_GET_STATES_SUCCESS,
  REGISTER_USER_GET_NEIGHBORHOOD,
  REGISTER_USER_GET_NEIGHBORHOOD_SUCCESS,
  REGISTER_USER_GET_CITIES,
  REGISTER_USER_GET_CITIES_SUCCESS,
  REGISTER_USER_KINDS_STORE,
  REGISTER_USER_KINDS_STORE_SUCCESS,
  REGISTER_USER_BANKS,
  REGISTER_USER_BANKS_SUCCESS
} from "../actions";


export const registerUserSetStep = (data) => {
  return {
    type: REGISTER_USER_SET_STEP,
    payload: { data }
  }
};

export const registerUserStepOne = (data) => {
  return {
    type: REGISTER_USER_STEP_ONE,
    payload: { data }
  }
};

export const registerUserStepThree = (data) => {
  return {
    type: REGISTER_USER_STEP_THREE,
    payload: { data }
  }
}

export const registerUserStepThreeSuccess = (data) => {
  return {
    type: REGISTER_USER_STEP_THREE_SUCCESS,
    payload: { data }
  }
}

export const registerUserStepTwo = (data) => {
  return {
    type: REGISTER_USER_STEP_TWO,
    payload: { data }
  }
}

export const registerUserStepTwoSucess = (data) => {
  return {
    type: REGISTER_USER_STEP_TWO_SUCCESS,
    payload: { data }
  }
}

export const registerUserStepTwoError = (data) => {
  return {
    type: REGISTER_USER_STEP_TWO_ERROR,
    payload: { data }
  }
}

export const registerUserStepFour = (data, history) => {
  return {
    type: REGISTER_USER_STEP_FOUR,
    payload: { data, history }
  }
}

export const registerUserStepFourSuccess = (data) => {
  return {
    type: REGISTER_USER_STEP_FOUR_SUCCESS,
    payload: { data }
  }
}

export const registerUserStepFourError = (data) => {
  return {
    type: REGISTER_USER_STEP_FOUR_ERROR,
    payload: { data }
  }
}

export const registerUserGetStates = () => {
  return {
    type: REGISTER_USER_GET_STATES,
    payload: {}
  }
}

export const registerUserGetStatesSuccess = (payload) => {
  return {
    type: REGISTER_USER_GET_STATES_SUCCESS,
    payload: { payload }
  }
}

export const registerUserGetCities = (data) => {
  return {
    type: REGISTER_USER_GET_CITIES,
    payload: data
  }
}

export const registerUserGetCitiesSuccess = (payload) => {
  return {
    type: REGISTER_USER_GET_CITIES_SUCCESS,
    payload: { payload }
  }
}

export const registerUserGetNeighborhoods = (data) => {
  return {
    type: REGISTER_USER_GET_NEIGHBORHOOD,
    payload: data
  }
}

export const registerUserGetNeighborhoodsSuccess = (payload) => {
  return {
    type: REGISTER_USER_GET_NEIGHBORHOOD_SUCCESS,
    payload: { payload }
  }
}

export const registerUserGetKindsStore = (data) => {
  return {
    type: REGISTER_USER_KINDS_STORE,
    payload: data
  }
}

export const registerUserGetKindsStoreSuccess = (payload) => {
  return {
    type: REGISTER_USER_KINDS_STORE_SUCCESS,
    payload: { payload }
  }
}

export const registerUserGetBanks = (data) => {
  return {
    type: REGISTER_USER_BANKS,
    payload: data
  }
}

export const registerUserGetBanksSuccess = (payload) => {
  return {
    type: REGISTER_USER_BANKS_SUCCESS,
    payload: { payload }
  }
}



